<template>
  <rapi-doc
    v-if="ready"
    :spec-url="specUrl"
    ref="rapidoc"
    theme="dark"
    font-size="large"
    render-style="focused"
    show-header="false"
    allow-server-selection="false"
    primary-color="#6811BE"
    bg-color="#111827"
    text-color="#cccccc"
    header-color="#212c42"
    persist-auth="true"
    sort-tags="true"
    sort-endpoints-by="method"
    allow-spec-file-download="true"
  />
</template>

<script>
import 'rapidoc'

export default {
  name: 'App',
  data () {
    return {
      ready: false,
      specUrl: window.DOCS_URL || process.env.VUE_APP_DOCS_URL
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = '/config.js'

    document.querySelector('body').appendChild(script)

    script.onload = () => {
      this.specUrl = window.DOCS_URL || process.env.VUE_APP_DOCS_URL

      this.ready = true

      this.$nextTick(() => {
        const sheet = new CSSStyleSheet()
        sheet.replaceSync(`#api-info button { width: unset !important }`)
        this.$refs.rapidoc.renderOptions.host.shadowRoot.adoptedStyleSheets.push(sheet)
      })
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

button {
  width: unset !important;
}
</style>
